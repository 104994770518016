import React from "react"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"
import Back from "../components/Layout/Back"
import Navigation from "../components/Layout/Navigation"

const Impressum = () => (
	<Layout>
		<SEO title="Impressum" />
		<Navigation contact={false} />
		<Back color={"#e9c46a"} />
		<div className="h-20"></div>
		<div>
			<div className=" mb-2 lato text-2xl lg:text-4xl text-center tracking-widest text-gray-800">
				Impressum
			</div>
			<div className="h-20"></div>
			<div class="flex justify-center">
				<div class="w-full lg:w-2/3 px-3 lg:px-0 text-sm lg:text-base">
					<div className="font-bold text-base lg:text-lg">
						Angaben gemäß § 5 TMG
					</div>
					<div className="text-sm lg:text-base my-2">
						<p className="font-bold">Benjamin Becker</p>
						<p>Missundestraße 50</p>
						<p>22769 Hamburg</p>
						<p>
							{" "}
							<div className=" my-2">Vertreten durch: </div>
							Benjamin Becker
						</p>
						<p>
							<div className="underline my-2">Kontakt:</div>
							<p>Telefon: 0160-8117881 </p>{" "}
							<p>
								{" "}
								E-Mail:{" "}
								<a href="mailto:becker@beckerundzietz.de">
									becker@beckerundzietz.de
								</a>
							</p>
						</p>
						<p>
							<div>Umsatzsteuer-Nummer: 41/015/01839</div>

							<div>Wirtschafts-ID: </div>
						</p>
					</div>

					<div className="text-sm lg:text-base my-2">
						<p className=" font-bold">Maximilian Zietz</p>
						<p>Hoheluftchaussee 151 </p>
						<p>20253 Hamburg</p>
						<p>
							{" "}
							<div className=" my-2">Vertreten durch: </div>
							Maximilian Zietz
						</p>
						<p>
							<div className="underline my-2">Kontakt:</div>
							<p>Telefon: 0151-61832750</p>{" "}
							<p>
								{" "}
								E-Mail:{" "}
								<a href="mailto:zietz@beckerundzietz.de">
									zietz@beckerundzietz.de
								</a>
							</p>
						</p>
						<p>
							<div>Umsatzsteuer-Nummer: 45/277/03595</div>

							<div>Wirtschafts-ID: </div>
						</p>
					</div>
					<p>
						<div className="font-bold text-base lg:text-lg my-2">
							Haftungsausschluss:{" "}
						</div>
						<div>
							<div className="font-bold text-base lg:text-lg my-2">
								Haftung für Inhalte
							</div>
							Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt.
							Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte
							können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter
							sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
							nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG
							sind wir als Diensteanbieter jedoch nicht verpflichtet,
							übermittelte oder gespeicherte fremde Informationen zu überwachen
							oder nach Umständen zu forschen, die auf eine rechtswidrige
							Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung
							der Nutzung von Informationen nach den allgemeinen Gesetzen
							bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
							erst ab dem Zeitpunkt der Kenntnis einer konkreten
							Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
							Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
						</div>
						<div className="font-bold text-base lg:text-lg my-2">
							Haftung für Links
						</div>
						Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
						Inhalte wir keinen Einfluss haben. Deshalb können wir für diese
						fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der
						verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber
						der Seiten verantwortlich. Die verlinkten Seiten wurden zum
						Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
						Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht
						erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten
						Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
						nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir
						derartige Links umgehend entfernen.
						<div className="font-bold text-base lg:text-lg my-2">
							Urheberrecht
						</div>
						Die durch die Seitenbetreiber erstellten Inhalte und Werke auf
						diesen Seiten unterliegen dem deutschen Urheberrecht. Die
						Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
						Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der
						schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers.
						Downloads und Kopien dieser Seite sind nur für den privaten, nicht
						kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser
						Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
						Dritter beachtet. Insbesondere werden Inhalte Dritter als solche
						gekennzeichnet. Sollten Sie trotzdem auf eine
						Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
						entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen
						werden wir derartige Inhalte umgehend entfernen.
						<div className="font-bold text-base lg:text-lg my-2">
							Datenschutz
						</div>
						Die Nutzung unserer Webseite ist in der Regel ohne Angabe
						personenbezogener Daten möglich. Soweit auf unseren Seiten
						personenbezogene Daten (beispielsweise Name, Anschrift oder
						eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
						auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
						Zustimmung nicht an Dritte weitergegeben. Wir weisen darauf hin,
						dass die Datenübertragung im Internet (z.B. bei der Kommunikation
						per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz
						der Daten vor dem Zugriff durch Dritte ist nicht möglich. Der
						Nutzung von im Rahmen der Impressumspflicht veröffentlichten
						Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
						angeforderter Werbung und Informationsmaterialien wird hiermit
						ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
						ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
						von Werbeinformationen, etwa durch Spam-Mails, vor.
					</p>
					<div className="my-2 text-sm">
						Impressum vom Impressum Generator der Kanzlei Hasselbach,
						Rechtsanwälte für Arbeitsrecht und Familienrecht
					</div>
				</div>
			</div>
		</div>
		<div className="h-20"></div>
	</Layout>
)

export default Impressum
